enum SelectTrimTypeGenerate {
	FixDays = 'next_trim_fixed_days',
	LactationDays = 'next_trim_lactation_based_days',
}

export enum LamenessStorageValue {
	LamenessFilterValue = 'lameness_filter_params',
	TrimFilterValue = 'trim_filter_params',
}

export interface IConstants {
	next_trim_fixed_days: number;
	next_trim_lactation_based_days: string;
	next_trim_field: SelectTrimTypeGenerate;
	next_examination_days: 3;
}

export interface IInitialStore {
	constants: IConstants;
	isLoadingConstants: boolean;
	errorConstantsMessage: string;
}
