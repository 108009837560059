import moment from 'moment';
import { Dispatch } from 'redux';
import { notifyUser } from '../../../store/notificationsSlice';
export const getDaysArr = (start: Date, end: Date) => {
	let arr: Date[] = [];
	for (
		let dt = new Date(start);
		dt <= new Date(end);
		dt.setDate(dt.getDate() + 1)
	) {
		arr.push(new Date(dt));
	}
	return arr;
};
export const roundingNumber = (number, rounding) => {
	const factor = Math.pow(10, rounding);
	return Math.round(number * factor) / factor;
};

export const timezoneOffsetInMilliseconds = () => {
	const now = moment();
	const timezoneOffsetInMinutes = now.utcOffset();
	const timezoneOffsetInMilliseconds = timezoneOffsetInMinutes * 60 * 1000;
	return timezoneOffsetInMilliseconds;
};

export const firstDayHandler = () => {
	const nowDate = moment();
	const startOfDay = nowDate.clone().subtract(1, 'day').endOf('day');
	const endOfDay = nowDate.endOf('day');
	return [startOfDay.valueOf() + 1, endOfDay.valueOf()];
};

export const getDatesArray = (days: number) => {
	const currentEndDate = moment().valueOf();
	const endDateTime = moment().startOf('day').valueOf();
	const startDate = moment(endDateTime).subtract(days, 'days').valueOf();

	return { startDate, endDate: currentEndDate };
};

export const getFutureDates = (days: number) => {
	const startDate = moment().startOf('day').valueOf();
	const endDate = moment(startDate).add(days, 'days').valueOf();

	return { startDate, endDate };
};

export const getOneMonthDate = () => {
	const currentEndDate = moment().valueOf();
	const endDateTime = moment().startOf('day').valueOf();
	const startDate = moment(endDateTime).subtract(1, 'months').valueOf();

	return { startDate, endDate: currentEndDate };
};

export const getFutureOneMonthDate = () => {
	const startDate = moment().startOf('day').valueOf();
	const endDate = moment(startDate).add(1, 'months').valueOf();
	return { startDate, endDate };
};

export const getYesterdayDate = () => {
	const currentEndDate = moment().startOf('day');
	const endDate = moment().startOf('day').subtract(1, 'minutes').valueOf();
	const startDate = moment(currentEndDate).subtract(1, 'days').valueOf();

	return { startDate, endDate };
};

export const formatPhoneNumber = phone => {
	const cleanedPhone = phone?.replace(/[^\d]/g, '');
	const formattedPhone = `+ ${cleanedPhone?.slice(0, 1)} (${cleanedPhone?.slice(
		1,
		4
	)}) - ${cleanedPhone?.slice(4, 7)} - ${cleanedPhone?.slice(
		7,
		9
	)} - ${cleanedPhone?.slice(9)}`;
	return formattedPhone;
};

export const copyTextToClipboard = (text: string, dispatch: Dispatch) => {
	const tempTextarea = document.createElement('textarea');
	tempTextarea.value = text;
	document.body.appendChild(tempTextarea);

	tempTextarea.select();
	tempTextarea.setSelectionRange(0, 99999);
	document.execCommand('copy');
	document.body.removeChild(tempTextarea);

	dispatch(
		notifyUser({
			title: 'Уведомление',
			description: 'Текст успешно скопирован',
			isError: false,
		})
	);
};

export const getHeaderTitle = (
	pathname,
	urlParams,
	rationInfo,
	currentGroupTitle
) => {
	switch (pathname) {
		case '/fill':
			return '';
		case `/fill/${urlParams.id}`:
			return '';
		case `/fill/${urlParams.id}/ration`:
			return '';
		case `/fill/${urlParams.id}/groups`:
			return '';
		case '/profile':
			return 'Профиль';
		case '/feed':
			return 'Кормовой стол';
		case '/feed/groups':
			return 'Группы';
		case `/feed/groups/${urlParams.groupkey}`:
			return currentGroupTitle;
		case `/feed/groups/${urlParams.groupkey}/edit`:
			return 'Редактирование группы';
		case '/feed/ration':
			return 'Рационы';
		case `/feed/ration/${urlParams.rationid}`:
			return rationInfo?.title;
		case '/feed/ration/create':
			return 'Создание нового рациона';
		case `/feed/ration/${urlParams.rationid}/edit`:
			return 'Редактирование рациона';
		case '/feed/plan':
			return 'План кормления';
		case '/feed/analytics':
			return 'Аналитика';
		case '/feed/analytics/feeding':
			return 'Аналитика кормления';
		case '/feed/plan/create':
			return 'Создание плана кормления';
		case '/feed/plan/edit':
		case '/feed/plan/edit/':
			return 'Редактирование плана кормления';
		case '/feed/deviations':
			return 'Обнаруженные отклонения';
		case '/feed/plan/history':
		case '/feed/plan/history/':
			return 'История изменений';
		case `/feed/plan/history/${urlParams.groupkey}`:
			return 'История изменений';
		case '/settings':
			return 'Настройки';
		case '/settings/management':
			return 'Управление пользователями';
		case '/settings/management/create-user':
			return 'Создание нового пользователя';
		case `/settings/management/${urlParams.userId}/edit`:
			return 'Редактирование пользователя';
		case '/cattles':
			return 'Поголовье';
		case '/cattles/lameness':
			return 'Хромота';
		case '/cattles/trim':
			return 'Обрезка копыт';
		case '/cattles/trim/edit':
			return 'Редактирование планируемой обрезки копыт';
		case '/cattles/trim/check-up':
			return 'Проведение обрезки копыт';
		case '/cattles/fatness':
			return 'Упитанность';
		case `/cattles/digitization/${urlParams.cameraId}`:
			return 'Оцифровка';
		case `/cattles/cow/${urlParams.tagNumber}`:
			return 'Корова';
		default:
			return '';
	}
};

export const getOneYearDate = () => {
	const currentEndDate = moment().valueOf();
	const endDateTime = moment().startOf('day').valueOf();
	const startDate = moment(endDateTime).subtract(1, 'year').valueOf();

	return { startDate: startDate, endDate: currentEndDate };
};

export const dayListHandler = (startDate?, endDate?) => {
	const nowDate = new Date(Date.now());
	const nowMonth = nowDate.getMonth();
	const currentYearNumber = nowDate.getFullYear();
	const currentMonthNumber = nowMonth + 1;
	const currentDayNumber = nowDate.getDate();
	const prevMonthNumber = nowMonth === 0 ? 12 : nowMonth + 1;
	const prevYearStr =
		prevMonthNumber > currentMonthNumber
			? currentYearNumber - 1
			: currentYearNumber;
	const prevMonthStr =
		prevMonthNumber < 10 ? '0' + prevMonthNumber : prevMonthNumber;
	const currentMonthStr =
		currentMonthNumber < 10 ? '0' + currentMonthNumber : currentMonthNumber;
	const currentDayStr =
		currentDayNumber < 10 ? '0' + currentDayNumber : currentDayNumber;
	const prevDate = `${prevYearStr}-${prevMonthStr}-${currentDayStr}`;
	const currentDate = `${currentYearNumber}-${currentMonthStr}-${currentDayStr}`;
	const result = getDaysArr(
		new Date(startDate ? startDate : prevDate),
		new Date(endDate ? endDate : currentDate)
	);
	return result;
};

export const getYearsOld = (timestamp: number, isCount?: boolean) => {
	if (timestamp) {
		const birthday = moment(timestamp);
		const today = moment();

		const years = today.diff(birthday, 'years');
		birthday.add(years, 'years');

		const months = today.diff(birthday, 'months');

		if (isCount) {
			const totalYears = years + months / 12;
			return totalYears.toFixed(0);
		} else {
			let result = '';
			if (years > 0) {
				result += years + ' ' + morphForWords(years, ['год', 'года', 'лет']);
			}

			if (months > 0) {
				if (result) {
					result += ' ';
				}
				result +=
					months + ' ' + morphForWords(months, ['месяц', 'месяца', 'месяцев']);
			}

			return result;
		}
	}
	return '';
};

export const morphForWords = (int, array) => {
	return array[
		int % 100 > 4 && int % 100 < 20
			? 2
			: [2, 0, 1, 1, 1, 2][int % 10 < 5 ? int % 10 : 5]
	];
};

export const getDaysBetweenDates = dateRange => {
	const startDate = moment(dateRange.startDate);
	const endDate = moment(dateRange.endDate);

	startDate.startOf('day');
	endDate.endOf('day');

	const daysDifference = endDate.diff(startDate, 'days');

	return Math.max(0, daysDifference);
};

export function getMaxOfArray(numArray) {
	return Math.max.apply(null, numArray);
}
