import { Box } from '@mui/material'
import { ICowLightInfo } from 'models/ICattlesData'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import CattlesApiService from 'services/cattlesApiServices'
import { addCowStatuses } from 'store/cattlesSlice'
import { useAppDispatch } from 'store/hooks'
import Preloader from 'ui/Preloader'
import CowMainInfo from './ui/CowMainInfo'
import HealthCard from './ui/HealthCard'
import WidjetsInPasportPage from './ui/WidjetsInPasportPage'
import YieldQtyCard from './ui/YieldQtyCard'

interface LocationState {
	cowId: number
}

const CowPasportPage = () => {
	const { tagNumber } = useParams()
	const location = useLocation()
	const state = location.state as LocationState
	const [cowId, setCowId] = useState(state?.cowId)
	const [cowInfo, setCowInfo] = useState<ICowLightInfo>(undefined)
	const dispatch = useAppDispatch()
	const fetchCowData = async (id: number) => {
		const cowId =
			id || (await CattlesApiService.getCowInfoByTagId(+tagNumber)).data.id
		setCowId(cowId)
		const { data } = await CattlesApiService.getCowLightInfoById(cowId)
		setCowInfo(data)
		const payloadAction = {
			yieldStatus: data.yield_status,
			treatmentStatus: data.treatment_status,
			fatnessStatus: data.fatness_status,
		}
		dispatch(addCowStatuses(payloadAction))
	}

	useEffect(() => {
		fetchCowData(cowId)
	}, [cowId, tagNumber])
	if (!cowInfo)
		return (
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flex: 1,
					height: '80vh',
				}}
			>
				<Preloader />
			</Box>
		)
	return (
		<Box
			sx={{
				padding: '0 16px',
				display: 'flex',
				flexDirection: 'column',
				gap: '24px',
			}}
		>
			<CowMainInfo cattleInfo={cowInfo} />
			<WidjetsInPasportPage cattleInfo={cowInfo} />
			<YieldQtyCard cowId={cowInfo.id} />
			<HealthCard cowId={cowInfo.id} />
		</Box>
	)
}

export default CowPasportPage
