import { Box, Dialog } from '@mui/material';
import { ReactComponent as CloseIcon } from 'assets/cross_close_logo.svg';
import { useState } from 'react';
import { COLORS } from 'styles/colors';
import { CardStandart } from 'ui/CardStandart';
import { TypographyHeader } from 'ui/typography/TypographyHeader';
import { TypographySecondary } from 'ui/typography/TypographySecondary';
import { ReactComponent as TagNumberIcon } from '../../../../assets/icons/tag-icon.svg';

const PhotoWidjet = props => {
	const { images } = props
	const [isShowCowModalPicture, setIsShowModalCowPicture] = useState<boolean>(false)
	const [showInfo, setShowInfo] = useState<string>('')
	const tagImage = images?.find(el => el.angle === 'tag')

	const renderImages = () => {
		const renderedImages = images?.slice(0, 3).map((el, index) => (
			<img
				key={index}
				style={{
					width: '30%',
					objectFit: 'cover',
					borderRadius: '4px',
					border: `1px solid ${COLORS.inputBorder}`,
				}}
				src={el.image}
				alt={`cow-preview${index}`}
			/>
		))

		const emptyPhotosCount = Math.max(0, 3 - images?.length)
		for (let i = 0; i < emptyPhotosCount; i++) {
			renderedImages.push(<EmptyPhoto key={`empty-photo-${i}`} noPhoto />)
		}

		return renderedImages
	}
	const onClickImage = (uri: string) => {
		setIsShowModalCowPicture(true)
		setShowInfo(uri)
	}
	const onCloseDialog = () => {
		setShowInfo('')
		setIsShowModalCowPicture(false)
	}
	return (
		<CardStandart
			sx={{
				p: '24px',
				width: '50%',
				display: 'flex',
				flexDirection: 'column',
				gap: '16px',
				height: '100%',
			}}
		>
			{images?.length === 0 ? (
				<EmptyPhoto noTag/>
			): (
					<>
						{images.map((el, index) => (
							<img
							onClick={() => onClickImage(el.image)}
							style={{
								width: '100%',
								height: '100%',
								objectFit: 'cover',
								borderRadius: '4px',
								border: `1px solid ${COLORS.inputBorder}`,
								cursor: 'pointer'
							}}
							src={el.image}
							alt={`cow-${index}`}
						/>
					))}
					</>
			)}
			{/* {tagImage ? (
				<Box height={'70%'}>
					<img
						style={{
							width: '100%',
							height: '100%',
							objectFit: 'cover',
							borderRadius: '4px',
							border: `1px solid ${COLORS.inputBorder}`,
						}}
						src={tagImage.image}
						alt={'tag-number'}
					/>
				</Box>
			) : (
				<EmptyPhoto noTag />
			)} 
			 <Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					width: '100%',
					height: '30%',
					gap: '16px',
				}}
			>
				{renderImages()}
			</Box> */}
			<Dialog maxWidth={'md'}
				open={isShowCowModalPicture} onClose={onCloseDialog}
				PaperProps={{
					style: {
						minWidth: '400px',
						borderRadius: '12px',
						padding: '24px',
					},
			}} >
				<Box sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center'
				}}>
					<TypographyHeader>
						Просмотр кадра
					</TypographyHeader>
					<CloseIcon onClick={onCloseDialog}
						stroke={COLORS.secondary}
						style={{ cursor: 'pointer' }} />
				</Box>
				<Box sx={{marginTop: '24px', maxWidth: '1100px'}}>
						<img
							style={{
								width: '100%',
								height: '100%',
								objectFit: 'cover',
							}}
							src={showInfo}
							alt={`cow-modal`}
						/>
				</Box>
			</Dialog>
		</CardStandart>
	)
}

export default PhotoWidjet

interface IEmptyPhotoProps {
	noTag?: boolean
	noPhoto?: boolean
}

const EmptyPhoto = (props: IEmptyPhotoProps) => {
	const { noTag, noPhoto } = props
	return (
		<Box
			sx={{
				width: '100%',
				height: '100%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				border: `1px solid ${COLORS.inputBorder}`,
				borderRadius: '4px',
				bgcolor: COLORS.background,
			}}
		>
			{noPhoto && (
				<TypographySecondary
					sx={{
						fontSize: '10px',
						color: COLORS.inputBorder,
						textAlign: 'center',
					}}
				>
					Нет фото
				</TypographySecondary>
			)}
			{noTag && <TagNumberIcon />}
		</Box>
	)
}
