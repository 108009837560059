import { Box, TableCell, tableCellClasses, TableRow } from '@mui/material';
import { morphForWords } from 'components/shared/processes';
import { TypographyPrimary } from 'ui/typography/TypographyPrimary';
import { TypographySecondary } from 'ui/typography/TypographySecondary';
import { IDonutDescriptionProps } from '../utils/models';

const DonutDescription = (props: IDonutDescriptionProps) => {
  const { description } = props;

  return (
    <Box sx={{ width: '50%' }}>
			{description.map((el, index) => (
				<TableRow key={index}
					sx={{
						[`& .${tableCellClasses.root}`]: {
							borderBottom: 'none',
						},
						
					}}>
					<TableCell padding={'none'} >
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								gap: '4px',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
								overflow: 'hidden',
							}}
						>
							<span
								style={{
									borderRadius: '50%',
									width: '8px',
									height: '8px',
									backgroundColor: el.color,
								}}
							>
								&nbsp;
							</span>

							<TypographySecondary
								sx={{
									textAlign: 'left',
									textOverflow: 'ellipsis',
									whiteSpace: 'nowrap',
									overflow: 'hidden',
								}}
							>
								{el.label}
							</TypographySecondary>
						</Box>
					</TableCell>
					<TableCell 	
							padding={'none'} sx={{paddingLeft: '16px'}}>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								gap: '4px'
							}}
						>
							<TypographyPrimary>
								{el.value}
							</TypographyPrimary>
							<TypographySecondary sx={{textOverflow: 'ellipsis',}}>
								{morphForWords(el.value, ['голова', 'головы', 'голов'])}
							</TypographySecondary>
						</Box>
					</TableCell>
				</TableRow>
      ))}
    </Box>
  );
}

export default DonutDescription;