import { Checkbox } from '@mui/material';
import { ReactComponent as CheckBox } from 'assets/icons/checkBox.svg';
import CheckBoxActive from 'assets/icons/checkBoxActive';
import { useEffect, useState } from 'react';
import { COLORS } from 'styles/colors';
import RangeDate from 'ui/datePicker/RangeDate';
import { TypographyPrimary } from 'ui/typography/TypographyPrimary';
import { InspectionStatus } from '../models';
import { inspectionStatuses } from '../processes';
const icon = <CheckBox />;
const checkedIcon = <CheckBoxActive />;

const InspectionContentBlock = props => {
	const { toggleFilter, inspectionStatus, setActiveFilters } = props;
	const [dateRange, setDateRange] = useState({
		startDate:
			inspectionStatus.startOf === null ? undefined : inspectionStatus.startOf,
		endDate:
			inspectionStatus.endOf === null ? undefined : inspectionStatus.endOf,
	});

	const onChangeCallback = value => {
		setDateRange({
			startDate: value[0],
			endDate: value[1],
		});
	};
	const isDisabledInspectionStatusDatepicker = !inspectionStatus.statuses.some(
		el =>
			el === InspectionStatus.Planned ||
			el === InspectionStatus.Expired ||
			el === InspectionStatus.Completed
	);

	useEffect(() => {
		setActiveFilters(prev => {
			return {
				...prev,
				inspectionStatus: {
					...prev.inspectionStatus,
					startOf: dateRange.startDate,
					endOf: dateRange.endDate,
				},
			};
		});
	}, [dateRange]);
	return (
		<>
			<ul>
				{inspectionStatuses.map(status => (
					<li
						onClick={() => toggleFilter('inspectionStatus', status.key)}
						key={status.id}
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							padding: '6px 0px',
						}}
					>
						<Checkbox
							icon={icon}
							disableRipple
							checkedIcon={checkedIcon}
							style={{ padding: '0px 8px 0px 0px', margin: '0px' }}
							checked={inspectionStatus.statuses.includes(status.key)}
						/>
						<TypographyPrimary sx={{ cursor: 'pointer' }}>
							{status.title}
						</TypographyPrimary>
					</li>
				))}
			</ul>
			<TypographyPrimary
				sx={{
					color: isDisabledInspectionStatusDatepicker
						? COLORS.secondaryFont
						: 'none',
					marginBottom: '16px',
				}}
			>
				Даты осмотра
			</TypographyPrimary>
			<RangeDate
				disabled={isDisabledInspectionStatusDatepicker}
				onChangeUpdateDate={onChangeCallback}
				startDate={dateRange.startDate}
				endDate={dateRange.endDate}
			/>
		</>
	);
};

export default InspectionContentBlock;
