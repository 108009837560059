import {
	ICheckupList,
	IFatness,
	IFiltersLamenessTable,
	InspectionStatus,
	LactationStatus,
	LamenessStatus,
	TreatmentStatus,
} from 'components/cattles/lamenessPage/shared/models'
import { EventsEnum } from 'models'
import {
	IAvailableFilterValuesResponse,
	ICowGraphResponse,
	ICowLightInfo,
	ICowsList,
	ICowsListResponse,
	IFatnessGraphResponse,
	IFatnessHealthTable,
	IHoofHealthTable,
	ILamenessGraphResponse,
	ITrims,
	ITrimsBatching,
	patchCowInfoType,
} from 'models/ICattlesData'
import axAPI from '../http'

export default class CattlesApiService {
	static getDailyStatistics = async (groupId: string | number) => {
		return await axAPI.get(
			`/cows/statistics${groupId !== 'all' ? '?group_id=' + groupId : ''}`
		)
	}
	static getInfoFromDigitizatedFrame = async (cameraId: number) => {
		return axAPI.get(`/cows/camera/${cameraId}/milking`)
	}
	static patchCowTagInfo = async (obj: patchCowInfoType) => {
		return await axAPI({
			method: 'PATCH',
			url: `/cows/${obj.cow_id}`,
			data: obj,
		})
	}
	static patchForceCowTagNumber = async (tagId: number, cowId: number) => {
		return await axAPI({
			method: 'PATCH',
			url: `/cows/${cowId}/force`,
			data: { tag_id: tagId },
		})
	}
	static buildQueryParams = (
		groupId?: number,
		fatnessPoint?: number[],
		tags?: number[],
		groups?: number[],
		fatness?: IFatness,
		examiners?: { ids: number[] },
		diagnoses?: { ids: number[] },
		lameness?: {
			statuses: LamenessStatus[]
			startOf?: number | string
			endOf?: number | string
		},
		treatmentStatus?: {
			statuses: TreatmentStatus[]
			startOf?: number | string
			endOf?: number | string
		},
		inspectionStatus?: {
			statuses: InspectionStatus[]
			startOf?: number
			endOf?: number
		},
		cowAge?: {
			startOf?: number | string
			endOf?: number | string
		},
		lactation?: {
			statuses: LactationStatus[]
			startOf?: number | string
			endOf?: number | string
		},
		lastTrim?: {
			startOf?: number | string
			endOf?: number | string
		},
		responsiblePersons?: { ids: number[] },
		isLateTrims?: boolean,
		dateRange?: {
			startDate: number
			endDate: number
		},
		page?: number,
		pageSize?: number
	): string => {
		const queryParams = new URLSearchParams()

		if (groupId) queryParams.append('group_id', groupId.toString())
		if (fatnessPoint && fatnessPoint.length > 0) {
			fatnessPoint.forEach(el => queryParams.append('fatness', el.toString()))
		}
		if (tags && tags.length > 0) {
			queryParams.append('tags', tags.join(','))
		}
		lameness &&
			lameness?.startOf !== '' &&
			queryParams.append('limpness_days_start', lameness.startOf.toString())
		lameness &&
			lameness?.endOf !== '' &&
			queryParams.append('limpness_days_end', lameness.endOf.toString())
		if (lameness?.statuses?.length > 0) {
			queryParams.append('limpness', lameness.statuses.join(','))
		}
		treatmentStatus &&
			treatmentStatus?.startOf !== '' &&
			queryParams.append(
				'treatment_days_start',
				treatmentStatus.startOf.toString()
			)
		treatmentStatus &&
			treatmentStatus?.endOf !== '' &&
			queryParams.append('treatment_days_end', treatmentStatus.endOf.toString())

		if (treatmentStatus && treatmentStatus?.statuses?.length > 0) {
			queryParams.append('treatment_status', treatmentStatus.statuses.join(','))
		}
		if (inspectionStatus && inspectionStatus?.statuses?.length > 0) {
			queryParams.append(
				'examination_status',
				inspectionStatus.statuses.join(',')
			)
		}
		const isIncludesPlannedExpiredCompleted =
			inspectionStatus?.statuses.includes(
				InspectionStatus.Planned ||
					InspectionStatus.Expired ||
					InspectionStatus.Completed
			)
		if (
			inspectionStatus &&
			inspectionStatus.startOf &&
			inspectionStatus.endOf &&
			isIncludesPlannedExpiredCompleted
		) {
			queryParams.append(
				'examination_start',
				inspectionStatus.startOf.toString()
			)
			queryParams.append('examination_end', inspectionStatus.endOf.toString())
		}
		cowAge &&
			cowAge?.startOf !== '' &&
			queryParams.append('age_start', cowAge.startOf.toString())
		cowAge &&
			cowAge?.endOf !== '' &&
			queryParams.append('age_end', cowAge.endOf.toString())
		if (fatness && fatness.startOf !== '' && fatness.endOf !== '') {
			queryParams.append('fatness_start', fatness.startOf.toString())
			queryParams.append('fatness_end', fatness.endOf.toString())
		}
		if (lactation && lactation?.startOf !== '' && lactation?.endOf !== '') {
			queryParams.append('lactation_days_start', lactation.startOf.toString())
			queryParams.append('lactation_days_end', lactation.endOf.toString())
		}
		if (lactation && lactation?.statuses?.length > 0) {
			const milkingStatus = lactation.statuses.filter(
				el =>
					el === LactationStatus.Lactating ||
					el === LactationStatus.NonLactating
			)
			const milkingTypes = lactation.statuses.filter(
				el =>
					el === LactationStatus.CommercialMilk ||
					el === LactationStatus.DefectiveMilk
			)
			if (milkingStatus.length > 0) {
				queryParams.append('milking_status', milkingStatus.join(','))
			}
			if (milkingTypes.length > 0) {
				queryParams.append('milk_types', milkingTypes.join(','))
			}
		}
		if (groups && groups.length > 0) {
			queryParams.append('groups', groups.join(','))
		}
		if (examiners && examiners?.ids?.length > 0) {
			queryParams.append('examiners', examiners.ids.join(','))
		}
		if (diagnoses && diagnoses?.ids?.length > 0) {
			queryParams.append('diagnoses', diagnoses.ids.join(','))
		}
		if (lastTrim && lastTrim?.startOf !== '' && lastTrim?.endOf !== '') {
			queryParams.append('last_trim_start', lastTrim.startOf.toString())
			queryParams.append('last_trim_end', lastTrim.endOf.toString())
		}
		if (responsiblePersons && responsiblePersons?.ids?.length > 0) {
			queryParams.append('trim_responsible', responsiblePersons.ids.join(','))
		}
		if (isLateTrims) {
			queryParams.append('late_trims', isLateTrims.toString())
		}
		if (dateRange && dateRange.startDate && dateRange.endDate) {
			queryParams.append('next_trim_start', dateRange.startDate.toString())
			queryParams.append('next_trim_end', dateRange.endDate.toString())
		}
		if (page) queryParams.append('page', page.toString())
		if (pageSize) queryParams.append('page_size', pageSize.toString())

		return queryParams.toString()
	}

	static getCowsList = async (
		groupId?: number,
		fatnessPoint?: number[],
		page?: number,
		pageSize?: number,
		params?: IFiltersLamenessTable
	) => {
		const queryParams = this.buildQueryParams(
			groupId,
			fatnessPoint,
			params?.tags,
			params?.groups?.ids,
			params?.fatness,
			params?.examiners,
			params?.diagnoses,
			params?.lameness,
			params?.treatmentStatus,
			params?.inspectionStatus,
			params?.cowAge,
			params?.lactation,
			params?.lastTrim,
			params?.responsiblePersons,
			params?.isLateTrims,
			params?.dateRange,
			page,
			pageSize
		)

		const url = `/cows/?${queryParams}`
		return await axAPI.get<ICowsListResponse>(url)
	}

	static getCowsCount = async (params?: IFiltersLamenessTable) => {
		const queryParams = this.buildQueryParams(
			undefined,
			undefined,
			params?.tags,
			params?.groups?.ids,
			params?.fatness,
			params?.examiners,
			params?.diagnoses,
			params?.lameness,
			params?.treatmentStatus,
			params?.inspectionStatus,
			params?.cowAge,
			params?.lactation,
			params?.lastTrim,
			params?.responsiblePersons,
			params?.isLateTrims,
			params?.dateRange
		)

		const url = `/cows/count?${queryParams}`
		return axAPI.get<number>(url)
	}
	static getAvailableFilterValues = async () => {
		const url = '/cows/available-filter-values'
		return await axAPI.get<IAvailableFilterValuesResponse>(url)
	}
	static getCowInfoByTagId = async (tagId: number) => {
		return await axAPI.get<ICowsList>(`/cows/tag/${tagId}`)
	}
	static getCowLightInfoById = async (cowId: number) => {
		return await axAPI.get<ICowLightInfo>(`/cows/${cowId}/v2`)
	}
	static getExaminersList = async () => {
		return await axAPI.get(`/cows/examiners`)
	}
	static getDiagnosisList = async () => {
		return await axAPI.get(`/cows/sicknesses`)
	}
	static getMilkingGraphByCowId = async (
		cowId: number,
		startDate: number,
		endDate: number
	): Promise<ICowGraphResponse> => {
		try {
			const response = await axAPI.get<ICowGraphResponse>(
				`/cows/${cowId}/milking/graph?start=${startDate}&end=${endDate}`
			)
			return response.data
		} catch (error) {
			console.error(error)
			throw error
		}
	}
	static getFatnessGraph = async (
		startDate: number,
		endDate: number,
		activeGroup?: number
	): Promise<IFatnessGraphResponse> => {
		try {
			const queryParams = new URLSearchParams()
			if (activeGroup) queryParams.append('group_id', activeGroup.toString())
			if (startDate) queryParams.append('start', startDate.toString())
			if (endDate) queryParams.append('end', endDate.toString())

			const url = `/cows/fatness/graph?${queryParams.toString()}`
			const response = await axAPI.get<IFatnessGraphResponse>(url)
			return response.data
		} catch (e) {
			console.error(e)
			throw e
		}
	}
	static getLamenessGraph = async (
		startDate: number,
		endDate: number,
		activeGroup?: number
	): Promise<ILamenessGraphResponse> => {
		try {
			const queryParams = new URLSearchParams()
			if (activeGroup) queryParams.append('group_id', activeGroup.toString())
			if (startDate) queryParams.append('start', startDate.toString())
			if (endDate) queryParams.append('end', endDate.toString())

			const url = `/cows/limpness/graph?${queryParams.toString()}`
			const response = await axAPI.get<ILamenessGraphResponse>(url)
			return response.data
		} catch (e) {
			console.error(e)
			throw e
		}
	}
	static getExaminationCowInfoByCowId = async (
		cow_id: number
	): Promise<ICheckupList[]> => {
		try {
			const response = await axAPI.get<ICheckupList[]>(
				`/cows/${cow_id}/examinations`
			)
			return response.data
		} catch (error) {
			console.error(error)
			throw error
		}
	}
	static postCheckupCowInfoByCowId = async (
		cow_id: number,
		data: ICheckupList
	) => {
		return axAPI({
			method: 'POST',
			url: `/cows/${cow_id}/examinations`,
			data,
		})
	}
	static getCheckChangedWeightCattlesQty = async () => {
		return axAPI({
			method: 'GET',
			url: `/check/${EventsEnum.CATTLE_QUANTITY}`,
			validateStatus: status => status === 404 || status === 200,
		})
	}
	static postNoChangedNeededForCattlesQty = async () => {
		return axAPI({
			method: 'POST',
			url: `/check/${EventsEnum.CATTLE_QUANTITY}`,
		})
	}
	static postComfirmDigitizatedCounter = async (cameraId: number) => {
		const response = await axAPI({
			method: 'POST',
			url: `/cows/milking/${cameraId}/comfirm`,
			data: { id: cameraId },
		})
		return response
	}
	static postCowTagInfo = async obj => {
		const formData = new FormData()
		formData.append('image', obj.image)
		formData.append('timestamp', obj.timestamp)
		formData.append('angle', 'tag')
		formData.append('cow_id', obj.cow_id)

		return await axAPI({
			method: 'POST',
			url: `/cows/${obj.cow_id}/images`,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
	}
	static getTrimsByCowId = async (
		cow_id: number[]
	): Promise<ITrimsBatching[]> => {
		try {
			const queryParams = new URLSearchParams()
			cow_id && queryParams.append('cow_id', cow_id.join(','))
			const response = await axAPI.get<ITrimsBatching[]>(
				`/cows/trims/batch?${queryParams}`
			)
			return response.data
		} catch (error) {
			console.error(error)
			throw error
		}
	}
	static getTodayTrims = async (cow_id: number[]): Promise<ITrims[]> => {
		try {
			const queryParams = new URLSearchParams()
			cow_id && queryParams.append('cow_id', cow_id.join(','))
			const response = await axAPI.get<ITrims[]>(`/cows/trims?${queryParams}`)
			return response.data
		} catch (error) {
			console.error(error)
			throw error
		}
	}
	static deleteTrimByTrimId = async (trim_id: number) => {
		try {
			await axAPI.delete(`/cows/trims/${trim_id}`)
		} catch (error) {
			console.error(error)
			throw error
		}
	}
	static patchTrimByTrimId = async (
		trim_id: number,
		targetDate: number,
		respId: number,
		comment?: string,
		done_dt?: number
	) => {
		try {
			await axAPI({
				method: 'PATCH',
				url: `/cows/trims/${trim_id}`,
				data: {
					target_date: targetDate,
					responsible_id: respId,
					comment,
					done_dt,
				},
			})
		} catch (error) {
			console.error(error)
			throw error
		}
	}
	static postTrimByCowId = async (
		cow_id: number,
		targetDate: number,
		respId: number,
		comment?: string,
		done_dt?: number
	) => {
		try {
			await axAPI({
				method: 'POST',
				url: `/cows/${cow_id}/trims`,
				data: {
					target_date: targetDate,
					responsible_id: respId,
					comment,
					done_dt,
				},
			})
		} catch (error) {
			console.error(error)
			throw error
		}
	}
	static getHoofHealthTableByCowId = async (cowId: number) => {
		try {
			const response = await axAPI.get<IHoofHealthTable[]>(
				`/cows/${cowId}/health/limbs`
			)
			return response.data
		} catch (error) {
			console.error(error)
			throw error
		}
	}
	static getFatnessHealthTableByCowId = async (cowId: number) => {
		try {
			const response = await axAPI.get<IFatnessHealthTable[]>(
				`/cows/${cowId}/health/fatness`
			)
			return response.data
		} catch (error) {
			console.error(error)
			throw error
		}
	}
}
