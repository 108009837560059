import { Box } from '@mui/material'
import { ReactComponent as LactationLogo } from 'assets/lactation-logo.svg'
import { ReactComponent as NotLactationLogo } from 'assets/not_lactation_logo.svg'
import { COLORS } from 'styles/colors'
import { CardStandart } from 'ui/CardStandart'
import InfoChipItem from 'ui/InfoChipItem'
import { TypographyPrimary16Bold } from 'ui/typography/TypographyPrimary16Bold'
import { TypographySecondary } from 'ui/typography/TypographySecondary'

const yieldStatusColor = {
	rejected: COLORS.error,
	dry: COLORS.health,
	ok: COLORS.secondary,
}

const bgColorLabel = {
	rejected: COLORS.error10,
	dry: COLORS.healthOpacity10,
}

const textLabel = {
	rejected: 'Браковка',
	dry: 'Сухостой',
}

const LactationWidjet = props => {
	const { dailyYield, lactationDay, lactationNumber, yieldStatus } = props

	return (
		<CardStandart
			sx={{
				width: '100%',
				padding: '24px',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
			}}
		>
			<Box display={'flex'} flexDirection={'row'} gap={'8px'} mr={'4px'}>
				<span>
					{yieldStatus === 'dry' ? (
						<NotLactationLogo fill={COLORS.secondary} />
					) : (
						<LactationLogo fill={yieldStatusColor[yieldStatus]} />
					)}
				</span>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						gap: '8px',
					}}
				>
					<TypographyPrimary16Bold
						sx={{
							color: yieldStatus === 'rejected' ? COLORS.error : COLORS.black,
						}}
					>
						{yieldStatus === 'dry' || !dailyYield ? (
							''
						) : (
							<>
								{dailyYield?.toLocaleString()}{' '}
								<span
									style={{
										color: COLORS.secondary,
										fontWeight: 400,
										fontSize: '12px',
									}}
								>
									л
								</span>
							</>
						)}
					</TypographyPrimary16Bold>
					<TypographySecondary>Надой</TypographySecondary>
				</Box>
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
					alignItems: 'flex-end',
				}}
			>
				{yieldStatus === 'rejected' || yieldStatus === 'dry' ? (
					<InfoChipItem
						bgColor={bgColorLabel[yieldStatus]}
						label={textLabel[yieldStatus]}
						labelColor={yieldStatusColor[yieldStatus]}
					/>
				) : (
					<span></span>
				)}
				<TypographySecondary>
					{lactationDay +
						' день лактации' +
						` (№${lactationNumber && lactationNumber})`}
				</TypographySecondary>
			</Box>
		</CardStandart>
	)
}

export default LactationWidjet
