import { Box, Divider } from '@mui/material';
import useCowsNextPage from 'hook/useNextPage';
import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { CardStandart } from 'ui/CardStandart';
import CustomPagination from 'ui/CustomPagination';
import { TypographyHeader } from 'ui/typography/TypographyHeader';
import CattlesTable from '../shared/CattlesTable';

interface CattlesListProps {
	activeFilter: number | string
}

const CattlesList: React.FC<CattlesListProps> = React.memo(props => {
	const { activeFilter } = props
	const [page, setPage] = useState<number>(1)
	useEffect(() => {
		setPage(1)
	}, [activeFilter])
	const initialPageSize = 36
	const prop = {
		page,
		activeFilter,
		initialPageSize,
		isFetchCowsListEnabled: true,
		isFetchComplete: true,
	}
	const { cowsList, totalCountPages, fetchingCowsList } = useCowsNextPage(prop)

	return (
		<CardStandart
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				padding: '24px',
			}}
		>
			{fetchingCowsList ? (
				<Skeleton width={150} height={20} />
			) : (
				<TypographyHeader>Список голов</TypographyHeader>
			)}
			<React.Fragment>
				<CattlesTable
					cattlesInfo={cowsList}
					fetchingCowsList={fetchingCowsList}
				/>
			</React.Fragment>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: '16px',
				}}
			>
				<Divider />
				<Box>
					{cowsList.length !== 0 && (
						<CustomPagination
							onChangeFromServer={setPage}
							count={initialPageSize}
							totalCount={totalCountPages}
						/>
					)}
				</Box>
			</Box>
		</CardStandart>
	)
})

export default CattlesList
