import {
	Table,
	TableBody,
	TableCell,
	tableCellClasses,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material'
import { ReactComponent as WarningLogo } from 'assets/barchart_info_logo.svg'
import { morphForWords } from 'components/shared/processes'
import { IFatnessHealthTable } from 'models/ICattlesData'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import CattlesApiService from 'services/cattlesApiServices'
import { COLORS } from 'styles/colors'
import Preloader from 'ui/Preloader'
import CustomTableRow from 'ui/table/CustomTableRow'
import TooltipHover from 'ui/TooltipHover'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import { TypographySecondary } from 'ui/typography/TypographySecondary'
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14'

interface FatnessHealthTableProps {
	cowId: number
}

const FatnessHealthTable = (props: FatnessHealthTableProps) => {
	const { cowId } = props
	const [healthInfo, setHealthInfo] = useState<IFatnessHealthTable[]>([])
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const transformForTableData = useCallback(
		(data: IFatnessHealthTable[]) => {
			return data?.map((el, index) => ({
				rowKey: `keyId=${index}`,
				height: '100%',
				cellItems: [
					{
						padding: '16px 8px 0px 0px',
						children: (
							<TypographySecondary14>
								{moment(el.date).format('DD.MM.YY')}
							</TypographySecondary14>
						),
					},
					{
						padding: '16px 8px 0px 0px',
						children: (
							<TypographyPrimary key={`groupTitle-${index}`}>
								{el.group_title}
							</TypographyPrimary>
						),
					},
					{
						padding: '16px 8px 0px 0px',
						children: (
							<>
								{el.ration_title ? (
									<TypographyPrimary key={`rationTitle-${index}`}>
										{el.ration_title}
									</TypographyPrimary>
								) : (
									<TypographySecondary>{'-'}</TypographySecondary>
								)}
							</>
						),
					},
					{
						padding: '16px 8px 0px 0px',
						children: (
							<>
								{el.fatness ? (
									<TypographyPrimary
										key={`limpness-${index}`}
										sx={{ color: el.critical ? COLORS.error : COLORS.black }}
									>
										{el.fatness}{' '}
										<span style={{ color: COLORS.secondaryFont }}>
											{morphForWords(el.fatness, ['балл', 'балла', 'баллов'])}
										</span>
									</TypographyPrimary>
								) : (
									<TypographySecondary>{'-'}</TypographySecondary>
								)}
							</>
						),
					},
					{
						padding: '16px 8px 0px 0px',
						children: (
							<>
								{el.fatness_norm ? (
									<TypographySecondary14>
										{el.fatness_norm}
									</TypographySecondary14>
								) : (
									<TooltipHover
										children={<WarningLogo style={{ color: COLORS.health }} />}
										title={
											'Актуализируйте данные по дням лактации в системе по надоям.'
										}
										placement={'bottom'}
									/>
								)}
							</>
						),
					},
					{
						padding: '16px 8px 0px 0px',
						children: (
							<>
								{el.psv ? (
									<TypographyPrimary key={`limpness-${index}`}>
										{el.psv}{' '}
										<span style={{ color: COLORS.secondaryFont }}>
											{'кг/гол'}
										</span>
									</TypographyPrimary>
								) : (
									<TypographySecondary>{'-'}</TypographySecondary>
								)}
							</>
						),
					},
				],
			}))
		},
		[cowId]
	)
	useEffect(() => {
		setIsLoading(true)
		CattlesApiService.getFatnessHealthTableByCowId(cowId)
			.then(res => {
				setHealthInfo(res.reverse())
			})
			.catch(err => console.error(err))
			.finally(() => setIsLoading(false))
	}, [])

	const transformedData = transformForTableData(healthInfo)
	return (
		<>
			<TableContainer
				sx={{
					marginTop: '24px',
					overflowY: 'auto',
					overflowX: 'hidden',
					height: '267px',
					'&::-webkit-scrollbar': {
						width: 4,
					},
					'&::-webkit-scrollbar-track': {
						backgroundColor: '#DBDBDB',
						borderRadius: 12,
					},
					'&::-webkit-scrollbar-thumb': {
						backgroundColor: '#5222D0',
						borderRadius: 12,
					},
				}}
			>
				<Table>
					<TableHead
						sx={{
							[`& .${tableCellClasses.root}`]: {
								backgroundColor: '#fff',
							},
						}}
					>
						<CustomTableRow
							rowKey={tableHead.rowKey}
							cellItems={tableHead.cellItems}
							rowBorderBottom={tableHead.rowBorderBottom}
							rowHeight={tableHead.height}
						/>
					</TableHead>
					<TableBody>
						{isLoading ? (
							<TableRow
								sx={{
									width: '100%',
									[`& .${tableCellClasses.root}`]: {
										borderBottom: 'none',
										height: '200px',
									},
								}}
							>
								<TableCell colSpan={6} align={'center'} padding={'none'}>
									<Preloader color={COLORS.secondaryFont} />
								</TableCell>
							</TableRow>
						) : transformedData?.length === 0 ? (
							<TableRow
								sx={{
									width: '100%',
									[`& .${tableCellClasses.root}`]: {
										borderBottom: 'none',
										height: '200px',
									},
								}}
							>
								<TableCell colSpan={6} align={'center'} padding={'none'}>
									<TypographySecondary14>
										Признаков упитанности ранее не было обнаружено
									</TypographySecondary14>
								</TableCell>
							</TableRow>
						) : (
							transformedData.map(el => (
								<CustomTableRow
									key={el.rowKey}
									rowKey={el.rowKey}
									cellItems={el.cellItems}
									rowHeight={el.height}
								/>
							))
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<TypographySecondary sx={{ marginTop: '24px' }}>
				{'Всего: ' + healthInfo.length}
			</TypographySecondary>
		</>
	)
}

export default FatnessHealthTable

const tableHead = {
	rowKey: 'tableHistoryViewHead',
	rowBorderBottom: COLORS.secondaryFontOpacity,
	height: '40px',
	cellItems: [
		{
			width: '9%',
			children: <TypographySecondary>Дата</TypographySecondary>,
		},
		{
			width: '23%',
			children: <TypographySecondary>Группа</TypographySecondary>,
		},
		{
			width: '23%',
			children: <TypographySecondary>Рацион</TypographySecondary>,
		},
		{
			width: '15%',
			children: (
				<TypographySecondary>
					Балл
					<br />
					упитанности
				</TypographySecondary>
			),
		},
		{
			width: '15%',
			children: (
				<TypographySecondary>
					Норма
					<br />
					упитанности
				</TypographySecondary>
			),
		},
		{
			width: '15%',
			children: <TypographySecondary>ПСВ</TypographySecondary>,
		},
	],
}
