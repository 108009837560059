import {
	Box,
	Table,
	TableBody,
	TableCell,
	tableCellClasses,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material'
import { PositionHoof } from 'components/cattles/lamenessPage/shared/models'
import { getTranslationStatus } from 'components/cattles/lamenessPage/shared/processes'
import { morphForWords } from 'components/shared/processes'
import { IHoofHealthTable } from 'models/ICattlesData'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import CattlesApiService from 'services/cattlesApiServices'
import { COLORS } from 'styles/colors'
import { ChipItem } from 'ui/ChipItem'
import Preloader from 'ui/Preloader'
import CustomTableRow from 'ui/table/CustomTableRow'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import { TypographySecondary } from 'ui/typography/TypographySecondary'
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14'
import { updateHoofDataCounts } from '../processes'

interface RoofHealthTableProps {
	cowId: number
}
const initHoodData = [
	{ title: 'Все', key: 'all', count: 0 },
	{ title: 'Переднее левое', key: PositionHoof.Front_Left, count: 0 },
	{ title: 'Переднее правое', key: PositionHoof.Front_Right, count: 0 },
	{ title: 'Заднее левое', key: PositionHoof.Rear_Left, count: 0 },
	{ title: 'Заднее правое', key: PositionHoof.Rear_Right, count: 0 },
]
const HoofHealthTable = (props: RoofHealthTableProps) => {
	const { cowId } = props
	const [healthInfo, setHealthInfo] = useState<IHoofHealthTable[]>([])
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [activeFilter, setActiveFilter] = useState('all')

	const transformForTableData = useCallback(
		(data: IHoofHealthTable[]) => {
			return data?.map((el, index) => ({
				rowKey: `keyId=${index}`,
				height: '100%',
				cellItems: [
					{
						padding: '16px 8px 0px 0px',
						children: (
							<TypographyPrimary>
								{moment(el.date).format('DD.MM.YY')}
							</TypographyPrimary>
						),
					},
					{
						padding: '16px 8px 0px 0px',
						children: (
							<>
								{el.limpness ? (
									<TypographyPrimary key={`limpness-${index}`}>
										{el.limpness}{' '}
										<span style={{ color: COLORS.secondaryFont }}>
											{morphForWords(el.limpness, ['балл', 'балла', 'баллов'])}
										</span>
									</TypographyPrimary>
								) : (
									<TypographySecondary14>{'-'}</TypographySecondary14>
								)}
							</>
						),
					},
					{
						padding: '16px 8px 0px 0px',
						children: (
							<TypographyPrimary
								sx={{
									color: el.event === 'Выбытие' ? COLORS.error : COLORS.black,
								}}
							>
								{el.event}
							</TypographyPrimary>
						),
					},
					{
						padding: '16px 8px 0px 0px',
						children: el.diagnosis ? (
							<Box
								display={'flex'}
								flexDirection={'column'}
								key={`diagnoses-${index}`}
								gap={'12px'}
							>
								{el.diagnosis.map((diagnose, diagIndex) => (
									<React.Fragment key={`diagnose-${index}-${diagIndex}`}>
										<TypographyPrimary>
											{diagnose.sickness
												.map(sicknes => sicknes.title)
												.join(', ')}
										</TypographyPrimary>
										<TypographySecondary>
											{getTranslationStatus(diagnose.position)}
										</TypographySecondary>
									</React.Fragment>
								))}
							</Box>
						) : (
							<TypographySecondary14>{'-'}</TypographySecondary14>
						),
					},
					{
						padding: '16px 8px 0px 0px',
						children: el.diagnosis ? (
							<Box
								display={'flex'}
								flexDirection={'column'}
								key={`diagnoses-${index}`}
								gap={'12px'}
							>
								{el.diagnosis.map((diagnose, diagIndex) => (
									<React.Fragment key={`diagnose-${index}-${diagIndex}`}>
										<TypographySecondary>
											{diagnose.comment}
										</TypographySecondary>
									</React.Fragment>
								))}
							</Box>
						) : (
							<TypographySecondary14>{'-'}</TypographySecondary14>
						),
					},
					{
						padding: '16px 8px 0px 0px',
						children: el.examiner ? (
							<TypographyPrimary>{el.examiner}</TypographyPrimary>
						) : (
							<TypographySecondary14>{'-'}</TypographySecondary14>
						),
					},
				],
			}))
		},
		[cowId]
	)

	useEffect(() => {
		setIsLoading(true)
		CattlesApiService.getHoofHealthTableByCowId(cowId)
			.then(res => {
				setHealthInfo(res.reverse())
			})
			.catch(err => console.error(err))
			.finally(() => setIsLoading(false))
	}, [])
	const hoofData = useMemo(() => {
		return updateHoofDataCounts(healthInfo, initHoodData)
	}, [healthInfo])
	const filteredData = useMemo(() => {
		return activeFilter === 'all'
			? healthInfo
			: healthInfo.filter(el =>
					el.diagnosis?.some(d => d.position.includes(activeFilter))
			  )
	}, [activeFilter, healthInfo])

	const transformedData = transformForTableData(filteredData)

	return (
		<>
			{hoofData?.map(el => (
				<ChipItem
					onClick={() => setActiveFilter(el.key)}
					key={el.key}
					label={el.title + ' ' + el.count}
					sx={{
						backgroundColor: activeFilter === el.key && COLORS.main,
						color: activeFilter === el.key && '#fff',
						margin: '16px 8px 16px 0px',
					}}
				/>
			))}
			<TableContainer
				sx={{
					overflowY: 'auto',
					overflowX: 'hidden',
					height: '267px',
					'&::-webkit-scrollbar': {
						width: 4,
					},
					'&::-webkit-scrollbar-track': {
						backgroundColor: '#DBDBDB',
						borderRadius: 12,
					},
					'&::-webkit-scrollbar-thumb': {
						backgroundColor: '#5222D0',
						borderRadius: 12,
					},
				}}
			>
				<Table>
					<TableHead
						sx={{
							[`& .${tableCellClasses.root}`]: {
								backgroundColor: '#fff',
							},
						}}
					>
						<CustomTableRow
							rowKey={tableHead.rowKey}
							cellItems={tableHead.cellItems}
							rowBorderBottom={tableHead.rowBorderBottom}
							rowHeight={tableHead.height}
						/>
					</TableHead>
					<TableBody>
						{isLoading ? (
							<TableRow
								sx={{
									width: '100%',
									[`& .${tableCellClasses.root}`]: {
										borderBottom: 'none',
										height: '200px',
									},
								}}
							>
								<TableCell colSpan={6} align={'center'} padding={'none'}>
									<Preloader color={COLORS.secondaryFont} />
								</TableCell>
							</TableRow>
						) : transformedData?.length === 0 ? (
							<TableRow
								sx={{
									width: '100%',
									[`& .${tableCellClasses.root}`]: {
										borderBottom: 'none',
										height: '200px',
									},
								}}
							>
								<TableCell colSpan={6} align={'center'} padding={'none'}>
									<TypographySecondary14>
										Болезней конечностей и обрезок ранее не было обнаружено
									</TypographySecondary14>
								</TableCell>
							</TableRow>
						) : (
							transformedData.map(el => (
								<CustomTableRow
									key={el.rowKey}
									rowKey={el.rowKey}
									cellItems={el.cellItems}
									rowHeight={el.height}
								/>
							))
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<TypographySecondary sx={{ marginTop: '24px' }}>
				{'Всего: ' + healthInfo.length}
			</TypographySecondary>
		</>
	)
}

export default HoofHealthTable

const tableHead = {
	rowKey: 'tableHistoryViewHead',
	rowBorderBottom: COLORS.secondaryFontOpacity,
	height: '40px',
	cellItems: [
		{
			width: '7%',
			children: <TypographySecondary>Дата</TypographySecondary>,
		},
		{
			width: '12%',
			children: (
				<TypographySecondary>
					Балл
					<br />
					хромоты
				</TypographySecondary>
			),
		},
		{
			width: '12%',
			children: <TypographySecondary>Событие</TypographySecondary>,
		},
		{
			width: '23%',
			children: (
				<TypographySecondary>
					Диагноз
					<br />
					Больное копыто
				</TypographySecondary>
			),
		},
		{
			width: '23%',
			children: <TypographySecondary>Комментарий</TypographySecondary>,
		},
		{
			width: '23%',
			children: <TypographySecondary>Исполнитель</TypographySecondary>,
		},
	],
}
