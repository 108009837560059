import { useEffect, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import ReactPhoneInput, { CountryData } from 'react-phone-input-2';
import ru from 'react-phone-input-2/lang/ru.json';
import 'react-phone-input-2/lib/material.css';
import { COLORS } from 'styles/colors';
import { TypographySecondary } from 'ui/typography/TypographySecondary';
import './styles.css';

interface PhoneInputInterface {
	name?: string;
	setValue?: UseFormSetValue<any>;
	onClick?: () => void;
	onBlur?: () => void;
	onChange?: (value: string) => void;
	isError?: boolean;
	value: string;
	autoFocus?: boolean;
	width?: string;
	dropDownWidth?: string;
	sx?: any;
	helperText?: string;
	isShowHelperText?: boolean;
	id?: string;
}

const PhoneInput = (props: PhoneInputInterface) => {
	const {
		name,
		setValue,
		onClick,
		onBlur,
		onChange,
		isError,
		value,
		autoFocus = false,
		width = '100%',
		dropDownWidth = '300px',
		helperText,
		isShowHelperText,
		id,
		sx = {},
	} = props;
	const [field, setField] = useState(value ?? '');
	const [isClick, setIsClick] = useState<boolean>(false);
	useEffect(() => {
		const flagElement = document.querySelector(
			'.flag-dropdown .selected-flag .flag'
		);
		const arrowElement = document.querySelector('.flag-dropdown .arrow');

		if (arrowElement) arrowElement.remove();
		if (flagElement && !field) flagElement.className = 'eath-style';
	}, []);

	return (
		<div
			id={id ? id : 'phoneInputWithFlag'}
			style={{
				...sx,
				width: width,
				position: 'relative',
			}}
		>
			<label
				htmlFor='phone'
				style={{
					color: isError
						? COLORS.error
						: field && isClick
						? COLORS.main
						: COLORS.secondaryFont,
					fontSize: field || isClick ? '10px' : '14px',
					position: 'absolute',
					transition: 'all .15s ease-in-out',
					top: field || isClick ? '-10px' : '17px',
					left: field || isClick ? '10px' : '60px',
					background: '#fff',
					borderRadius: '12px',
					padding: '0 5px',
					cursor: 'auto',
					zIndex: 99999,
				}}
			>
				Номер телефона
			</label>
			<ReactPhoneInput
				localization={ru}
				preferredCountries={['ru', 'by', 'kz']}
				preserveOrder={['preferredCountries']}
				placeholder={''}
				value={field}
				specialLabel=''
				inputProps={{
					id: 'phone',
					autoFocus: autoFocus,
					autoComplete: 'tel',
				}}
				onClick={() => {
					onClick && onClick();
					setIsClick(true);
				}}
				onChange={(value, country: CountryData, event, formattedValue) => {
					setField(value);
					setValue && setValue(name, value);
					onChange && onChange(value);
				}}
				onBlur={() => {
					setIsClick(false);
					onBlur && onBlur();
				}}
				containerStyle={{
					width: '100%',
				}}
				inputStyle={{
					width: '100%',
					height: '54px',
					border: `1px solid ${isError ? COLORS.error : COLORS.inputBorder}`,
					borderRadius: '8px',
					fontSize: '14px',
				}}
				buttonStyle={{
					borderRadius: '8px',
					margin: '4px',
					backgroundColor: COLORS.background,
				}}
				dropdownStyle={{
					width: dropDownWidth,
					margin: '10px -3px',
					borderRadius: '8px',
					borderColor: COLORS.secondary,
				}}
				disableDropdown={false}
			/>
			{isShowHelperText && (
				<TypographySecondary>{helperText}</TypographySecondary>
			)}
		</div>
	);
};

export default PhoneInput;
