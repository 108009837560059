import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	tableCellClasses,
} from '@mui/material'
import { ICowsList } from 'models/ICattlesData'
import { useNavigate } from 'react-router-dom'
import { COLORS } from 'styles/colors'

import SelectSmall from 'ui/menu/SelectForm'
import TableSkeleton from 'ui/skeleton/TableSkeleton'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import { TypographySecondary } from 'ui/typography/TypographySecondary'
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14'
import { TypographySecondaryBold16 } from 'ui/typography/TypographySecondaryBold16'
import { ReactComponent as ArrowNext } from '../../../../assets/fill_back_arrow.svg'
import { ReactComponent as TagNumberIcon } from '../../../../assets/icons/tag-icon.svg'

const typeForFilter = [
	{ type: 1, value: '1 балл' },
	{ type: 1.25, value: '1,25 балла' },
	{ type: 1.5, value: '1,5 балла' },
	{ type: 1.75, value: '1,75 балла' },
	{ type: 2, value: '2 балла' },
	{ type: 2.25, value: '2,25 балла' },
	{ type: 2.5, value: '2,5 балла' },
	{ type: 2.75, value: '2,75 балла' },
	{ type: 3, value: '3 балла' },
	{ type: 3.25, value: '3,25 балла' },
	{ type: 3.5, value: '3,5 балла' },
	{ type: 3.75, value: '3,75 балла' },
	{ type: 4, value: '4 балла' },
	{ type: 4.25, value: '4,25 балла' },
	{ type: 4.5, value: '4,5 балла' },
	{ type: 4.75, value: '4,75 балла' },
	{ type: 5, value: '5 баллов' },
]
interface IFatnessTableProps {
	cattlesInfo: ICowsList[]
	setActiveFilterTypeSelect: any
	fetchingCowsList: boolean
}
const FatnessTable = (props: IFatnessTableProps) => {
	const { cattlesInfo, setActiveFilterTypeSelect, fetchingCowsList } = props
	const navigate = useNavigate()
	const navigateCowPasportPage = (tagId, id) => {
		navigate(`/cattles/cow/${tagId}`, { state: { cowId: id } })
		window.scrollTo(0, 0)
	}
	return (
		<TableContainer
			sx={{
				overflowY: 'auto',
				overflowX: 'hidden',
				height: 'calc(100vh - 350px)',
				'&::-webkit-scrollbar': {
					width: 4,
				},
				'&::-webkit-scrollbar-track': {
					backgroundColor: '#DBDBDB',
					borderRadius: 12,
				},
				'&::-webkit-scrollbar-thumb': {
					backgroundColor: '#5222D0',
					borderRadius: 12,
				},
			}}
		>
			<Table stickyHeader>
				<TableHead
					sx={{
						[`& .${tableCellClasses.root}`]: {
							backgroundColor: '#fff',
							marginBottom: '10%',
						},
					}}
				>
					<TableRow sx={{ width: '100%', height: '50px' }}>
						<TableCell align={'left'} padding={'none'} width={'10%'}>
							<Box
								display={'flex'}
								flexDirection={'row'}
								alignItems={'center'}
								gap={'4px'}
							>
								<TagNumberIcon />
								<TypographySecondary>
									Номер
									<br />
									бирки
								</TypographySecondary>
							</Box>
						</TableCell>
						<TableCell align={'left'} padding={'none'} width={'20%'}>
							<TypographySecondary>Группа</TypographySecondary>
						</TableCell>
						<TableCell align={'left'} padding={'none'} width={'20%'}>
							<TypographySecondary>Период лактации</TypographySecondary>
						</TableCell>
						<TableCell align={'left'} padding={'none'} width={'20%'}>
							<SelectSmall
								typeArray={typeForFilter}
								isCheckbox
								isMultiplaySelect
								state={setActiveFilterTypeSelect}
								placeholder={'Упитанность'}
								isClearIcon
							/>
						</TableCell>
						<TableCell align={'left'} padding={'none'} width={'22%'}>
							<TypographySecondary>
								Норма <br />
								упитанности
							</TypographySecondary>
						</TableCell>
						<TableCell align={'left'} padding={'none'} width={'8%'}>
							<TypographySecondary>Действия</TypographySecondary>
						</TableCell>
					</TableRow>
				</TableHead>
				{fetchingCowsList ? (
					<TableBody>
						<TableRow>
							<TableCell colSpan={6} align={'center'} padding={'none'}>
								<TableSkeleton
									widthForBody={'100%'}
									heightForBody={'34px'}
									rowCount={36}
									totalHeight={'calc(100vh - 350px)'}
								/>
							</TableCell>
						</TableRow>
					</TableBody>
				) : cattlesInfo.length === 0 ? (
					<TableBody>
						<TableRow
							sx={{
								width: '100%',
								[`& .${tableCellClasses.root}`]: {
									borderBottom: 'none',
									height: '300px',
								},
							}}
						>
							<TableCell colSpan={6} align={'center'} padding={'none'}>
								<TypographySecondaryBold16>
									Нет данных
								</TypographySecondaryBold16>
							</TableCell>
						</TableRow>
					</TableBody>
				) : (
					<TableBody>
						{cattlesInfo.map((el, index) => {
							return (
								<TableRow
									key={index}
									sx={{
										height: '34px',
										'&:hover': {
											background: COLORS.mainSecondaryOpacity,
										},
										[`& .${tableCellClasses.root}`]: {
											borderBottom: 'none',
										},
									}}
								>
									<TableCell align={'left'} padding={'none'}>
										<Box
											display={'flex'}
											flexDirection={'row'}
											alignItems={'center'}
											gap={'4px'}
										>
											{el.critical_state && (
												<span
													style={{
														display: 'inline-block',
														borderRadius: '50%',
														backgroundColor: COLORS.error,
														width: '8px',
														height: '8px',
													}}
												></span>
											)}
											<TypographyPrimary>{el.tag_id}</TypographyPrimary>
										</Box>
									</TableCell>
									<TableCell align={'left'} padding={'none'}>
										<TypographyPrimary>{el.group.title}</TypographyPrimary>
									</TableCell>
									{!el.lactation_state ? (
										<TableCell colSpan={3} align={'left'} padding={'none'}>
											<TypographySecondary>
												Данные по дням лактации недействительны. Актуализируйте
												систему по надоям для точного расчета.
											</TypographySecondary>
										</TableCell>
									) : (
										<>
											<TableCell align={'left'} padding={'none'}>
												<TypographyPrimary>
													{el.lactation_state}{' '}
													<span
														style={{
															fontSize: '12px',
															color: COLORS.secondary,
														}}
													>
														{'/ ' + el.lactation_length + ' день'}
													</span>
												</TypographyPrimary>
											</TableCell>
											<TableCell align={'left'} padding={'none'}>
												<TypographyPrimary
													sx={{
														color: true ? COLORS.error : COLORS.black,
													}}
												>
													{el.fatness}{' '}
													<span
														style={{
															fontSize: '12px',
															color: COLORS.secondary,
														}}
													>
														б
													</span>
												</TypographyPrimary>
											</TableCell>
											<TableCell align={'left'} padding={'none'}>
												<TypographySecondary14>
													{el.fatness_norm}
												</TypographySecondary14>
											</TableCell>
										</>
									)}

									<TableCell align={'right'}>
										<Box
											onClick={() => navigateCowPasportPage(el.tag_id, el.id)}
										>
											<ArrowNext
												style={{
													cursor: 'pointer',
													transform: 'rotate(180deg)',
												}}
												stroke={COLORS.secondary}
											/>
										</Box>
									</TableCell>
								</TableRow>
							)
						})}
					</TableBody>
				)}
			</Table>
		</TableContainer>
	)
}

export default FatnessTable
