import { Box } from '@mui/material'
import { ICowLightInfo } from 'models/ICattlesData'
import moment from 'moment'
import { COLORS } from 'styles/colors'
import { CardStandart } from 'ui/CardStandart'
import { TypographyHeader } from 'ui/typography/TypographyHeader'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14'

type InfoTypeProps = {
	cattleInfo: ICowLightInfo
}

const FoodInfoWidjet = (props: InfoTypeProps) => {
	const { cattleInfo } = props
	const yesterdayDate = moment().subtract(1, 'days').format('DD.MM.YYYY')

	return (
		<CardStandart sx={{ padding: '24px', height: '100%', width: '100%' }}>
			<Box display={'flex'} flexDirection={'column'} gap={'16px'}>
				<Box
					display={'flex'}
					flexDirection={'row'}
					alignItems={'center'}
					justifyContent={'space-between'}
					marginBottom={'8px'}
				>
					<TypographyHeader>Кормление</TypographyHeader>
					<TypographySecondary14>
						{'Вчера, ' + yesterdayDate}
					</TypographySecondary14>
				</Box>
				<TypographyPrimary>
					<span style={{ color: COLORS.secondary, marginRight: '8px' }}>
						Рацион:
					</span>
					{cattleInfo?.ration?.title ? cattleInfo?.ration?.title : ''}
				</TypographyPrimary>
				<TypographyPrimary>
					<span style={{ color: COLORS.secondary, marginRight: '8px' }}>
						ПСВ:
					</span>
					{cattleInfo?.ration?.dry_matter_total
						? cattleInfo?.ration?.dry_matter_total
						: ''}
				</TypographyPrimary>
			</Box>
		</CardStandart>
	)
}

export default FoodInfoWidjet
