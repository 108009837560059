import { IHoofHealthTable } from 'models/ICattlesData'

export const updateHoofDataCounts = (data: IHoofHealthTable[], hoofData) => {
	const updatedHoofData = hoofData.map(item => ({
		...item,
		count: 0,
	}))

	data.forEach(exam => {
		exam.diagnosis?.forEach(diagnose => {
			const hoof = diagnose?.position
			const hoofItem = updatedHoofData.find(item => item.key === hoof)
			if (hoofItem) {
				hoofItem.count += 1
			}
			const allItem = updatedHoofData.find(item => item.key === 'all')
			if (allItem) {
				allItem.count += 1
			}
		})
	})
	return updatedHoofData
}
