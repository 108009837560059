import { Box } from '@mui/material';
import { ReactNode } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import { useNavigate } from 'react-router-dom';
import { CardStandart } from 'ui/CardStandart';
import { TypographyHeader } from 'ui/typography/TypographyHeader';
import CustomTooltip from '../../components/shared/CustomTooltip';

interface IWidjetProps {
	title: string | JSX.Element;
	tooltipContent: string;
	content: ReactNode;
	pathNavigate?: string;
}

const CardWidjet = (props: IWidjetProps) => {
	const { title, tooltipContent, content, pathNavigate } = props;
	const navigate = useNavigate();
	const handleClickNavigate = () => {
		pathNavigate && navigate(pathNavigate);
	};
	return (
		<CardStandart>
			<Box
				onClick={() => handleClickNavigate()}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					padding: '24px',
					gap: '24px',
					height: '243px',
					cursor: !pathNavigate ? 'auto' : 'pointer',
					'@media (max-width: 1200px)': {
						gap: '0px',
					},
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<TypographyHeader>{title}</TypographyHeader>
					{tooltipContent && (
						<CustomTooltip
							placement={'top'}
							width={'24px'}
							height={'24px'}
							info={false}
							title={tooltipContent}
						/>
					)}
				</Box>
				{content}
			</Box>
		</CardStandart>
	);
};

export default CardWidjet;
